import React, { useState, useEffect } from 'react';
import './GuideCharacter.css';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const characterImages = [
  'character1.png', // TOPページのキャラクター画像
  'character2.png', // mint-nftページのキャラクター画像
  'character3.png', // view-nftページのキャラクター画像
  'character4.png', // download-nftページのキャラクター画像
  // 他のキャラクター画像（必要に応じて追加）
];

const GuideCharacter = ({ currentStep, initialMessages }) => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  const messages = initialMessages || ["デフォルトのメッセージ"];
  const message = messages[progress];

  useEffect(() => {
    setProgress(0); // ステップが変わるたびにメッセージをリセット
  }, [currentStep]);

  const nextMessage = () => {
    if (progress < messages.length - 1) {
      setProgress(progress + 1);
    } else {
      switch (currentStep) {
        case 1: // TOPページ
          navigate('/mint-nft');
          break;
        case 2: // mint-nftページ
          navigate('/view-nft');
          break;
        case 3: // view-nftページ
          navigate('/download-nft');
          break;
        // download-nftページから次へは機能しないため、何もしない
      }
    }
  };

  const prevMessage = () => {
    if (progress > 0) {
      setProgress(progress - 1);
    } else {
      switch (currentStep) {
        case 2: // mint-nftページ
          navigate('/');
          break;
        case 3: // view-nftページ
          navigate('/mint-nft');
          break;
        case 4: // download-nftページ
          navigate('/view-nft');
          break;
        // TOPページから戻るは機能しないため、何もしない
      }
    }
  };

  const characterImg = require(`../assets/images/${characterImages[currentStep - 1]}`);

  return (
    <div className="guide-character">
      <motion.img 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        src={characterImg}
        alt="ガイドキャラクター" 
      />
      <motion.div 
        className="message-box"
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <p>{message}</p>
      </motion.div>
      <button onClick={nextMessage}>次へ</button>
      <button onClick={prevMessage}>戻る</button>
    </div>
  );
};

export default GuideCharacter;
