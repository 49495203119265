import React, { useState, useEffect } from 'react';
import { useWallet } from '../../contexts/WalletContext';
import { ethers } from 'ethers';
import abi from '../../config/abi.json';
import { useSDK } from "@thirdweb-dev/react";
import axios from 'axios';
import WalletConnect from '../WalletConnect';
import './NFTImageDownloader.css'; // CSSファイルをインポート

const NFTcontract = process.env.REACT_APP_NFTCONTRACT;

const NFTImageDownloader = () => {
    const { state } = useWallet();
    const [nftImages, setNftImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const sdk = useSDK();

    useEffect(() => {
        const fetchNFTImages = async () => {
            if (!state.address) return;

            let provider = typeof window.ethereum !== 'undefined'
                ? new ethers.providers.Web3Provider(window.ethereum)
                : sdk.getProvider();
                
            const contract = new ethers.Contract(NFTcontract, abi, provider);

            const transferEventFilter = contract.filters.Transfer(null, state.address);
            const transferEvents = await contract.queryFilter(transferEventFilter);

            let imageUrls = [];
            for (const event of transferEvents) {
                const tokenId = event.args[2].toString();

                try {
                    const tokenURI = await contract.tokenURI(tokenId);
                    const metaDataResponse = await axios.get(tokenURI);
                    const metaData = metaDataResponse.data;
                    imageUrls.push(metaData.image);
                } catch (error) {
                    console.error(`Error fetching metadata for token ID ${tokenId}:`, error);
                }
            }

            setNftImages(imageUrls);
        };

        fetchNFTImages();
    }, [state.address, state.signer, sdk]);

    const downloadImage = () => {
        if (!nftImages[currentImageIndex]) return;

        const link = document.createElement('a');
        link.href = nftImages[currentImageIndex];
        link.download = `NFTImage_${currentImageIndex}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const showNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % nftImages.length);
    };

    const showPreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + nftImages.length) % nftImages.length);
    };

    if (!state.address) {
        return (
            <div>
                <h2>Connect Your Wallet</h2>
                <WalletConnect />
            </div>
        );
    }

    return (
        <div className="nft-image-downloader">
            <div className="slider">
                <button className="slider-button" onClick={showPreviousImage}>&lt;</button>
                <div className="image-container">
                    {nftImages[currentImageIndex] && (
                        <img src={nftImages[currentImageIndex]} alt="NFT" />
                    )}
                </div>
                <button className="slider-button" onClick={showNextImage}>&gt;</button>
            </div>
            <button className="download-button" onClick={downloadImage}>Download Image</button>
        </div>
    );
};

export default NFTImageDownloader;
