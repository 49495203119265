import React from 'react';
import NFTImageDownloader from '../components/download/NFTImageDownloader';

function NFTdownload() {
  return (
    <div className="component-container">
    <NFTImageDownloader />
    </div>
  );
}

export default NFTdownload;