import React from 'react';
import NFTViewer from '../components/NFTViewer/NFTViewer';


function ViewNFT() {
  return (
    <div className="component-container">
    <NFTViewer />
    </div>
  );
}

export default ViewNFT;
