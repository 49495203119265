import React, { useState, useEffect, useCallback } from 'react';
import { useWallet } from '../../contexts/WalletContext';
import { ethers } from 'ethers';
import abi from '../../config/abi.json';
import { useSDK, useSigner } from "@thirdweb-dev/react";
import WalletConnect from '../WalletConnect';
import { useNavigate } from 'react-router-dom';
import './NFTViewer.css';
import { FaRedo } from 'react-icons/fa';
import axios from 'axios';

const NFTcontract = process.env.REACT_APP_NFTCONTRACT;

const NFTViewer = () => {
    const { state } = useWallet();
    const [nfts, setNfts] = useState([]);
    const [loadedTokenIds, setLoadedTokenIds] = useState(new Set());
    const thirdwebSigner = useSigner();
    const sdk = useSDK();
    const navigate = useNavigate();

    const fetchNFTs = useCallback(async () => {
        if (!state.address) return;
        console.log("Fetching NFTs...");

        let signer, address;
        if (thirdwebSigner && thirdwebSigner.originalSigner) {
            signer = thirdwebSigner.originalSigner;
            address = await signer.getAddress();
        } else {
            signer = thirdwebSigner || sdk.getProvider().getSigner();
            address = state.address;
        }

        const contract = new ethers.Contract(NFTcontract, abi, signer);
        const transferEventFilter = contract.filters.Transfer(null, address);
        const transferEvents = await contract.queryFilter(transferEventFilter);
        console.log("Transfer events:", transferEvents);

        const nftData = [];
        for (const event of transferEvents) {
            const tokenId = event.args[2].toString();
            if (loadedTokenIds.has(tokenId)) continue;

            setLoadedTokenIds(prevIds => new Set([...prevIds, tokenId])); // トークンIDを追加

            try {
                const tokenURI = await contract.tokenURI(tokenId);
                const metaDataResponse = await axios.get(tokenURI);
                const metaData = metaDataResponse.data;
                nftData.push({ tokenId, metaData });

                console.log(`Fetched metadata for token ID ${tokenId}:`, metaData);
            } catch (error) {
                console.error(`Error fetching metadata for token ID ${tokenId}:`, error);
            }
        }

        setNfts(nftData);
        console.log("NFT data set:", nftData);
    }, [state.address, sdk, thirdwebSigner]);

    useEffect(() => {
        fetchNFTs();
    }, [fetchNFTs]);

    const handleDownloadClick = () => {
        navigate('/download-nft');
    };

    const handleReload = () => {
        fetchNFTs();
    };

    return (
        <div>
            <div className="wallet-and-buttons">
                <WalletConnect />
                <button onClick={handleDownloadClick}>NFT画像をダウンロードしよう！</button>
                <button className="reload-button" onClick={handleReload}>
                    <FaRedo className="faRedo" />
                </button>
            </div>
            {nfts.length === 0 ? (
                <p>You don't own any NFTs.</p>
            ) : (
                <div className="nft-grid">
                    {nfts.map((nft, index) => (
                        <div key={index} className="nft-item">
                            <strong>TokenID:</strong> {nft.tokenId} <br />
                            <strong>Name:</strong> {nft.metaData.name} <br />
                            <strong>Description:</strong> {nft.metaData.description} <br />
                            <img src={nft.metaData.image} alt={nft.metaData.name} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default NFTViewer;
