import React, { useState } from 'react';
import { useSDK, useSigner } from "@thirdweb-dev/react";

import { ethers } from 'ethers';
import axios from 'axios';
import ExternalMinterABI from '../config/ExternalABI.json';
import ForwarderContractABI from '../config/ForwarderABI.json';
import { useWallet } from '../contexts/WalletContext'; // ここでuseWalletをインポート
import { useNavigate } from 'react-router-dom'; // React RouterのuseNavigateをインポート

import './MintNFT.css';

const MintNFT = () => {
    const [minting, setMinting] = useState(false);
    const [mintSuccess, setMintSuccess] = useState(false); // ミント成功の状態を追跡
    const navigate = useNavigate(); // useNavigateフックを使用


    const { state } = useWallet();
    const thirdwebSigner = useSigner(); // ThirdwebのSignerフックを使用
    const sdk = useSDK();

    // コントラクトのアドレス設定
    const forwarderAddress = "0x7aCF6170a893c8a5b50d42482b0c32583649e370";
    const externalMinterAddress = "0x20914713c0B26cB8108f71E46dAF37166e9e6838";

    const mintNFT = async () => {
        setMinting(true);
        setMintSuccess(false); // 追加: ミントを開始するときに成功状態をリセット


        try {
          let provider;
          // MetaMaskがインストールされているかをチェックし、かつアクティブな状態かどうかもチェック
          if (typeof window.ethereum !== 'undefined' && window.ethereum.selectedAddress) {
              console.log("Using MetaMask provider");
              provider = new ethers.providers.Web3Provider(window.ethereum); // MetaMaskのプロバイダーを使用
          } else {
              // MetaMaskが非アクティブか、インストールされていない場合
              console.log("Using Thirdweb embeddedWallet provider");
              provider = sdk.getProvider(); // ThirdwebのSDKからプロバイダーを取得
          }
          
          // プロバイダーが見つからない場合はエラーを出力
          if (!provider) {
              console.error("No provider detected");
              return;
          }
  
          let signer;
          // MetaMaskが選択されているアドレスを持っているかチェック
          if (typeof window.ethereum !== 'undefined' && window.ethereum.selectedAddress) {
              console.log("Using MetaMask for signing");
              signer = provider.getSigner(); // MetaMaskの署名者を使用
          } else if (thirdwebSigner) {
              console.log("Using embeddedWallet for signing");
              signer = thirdwebSigner; // Thirdwebの署名者を使用
          } else {
              console.error("No signer available. Please connect to a wallet.");
              return;
          }

          const signerAddress = await signer.getAddress();
  
          // 署名者をログに出力
          console.log("Signer:", signer);

            // Nonceの取得 (リレイヤーのアドレスから)
            const forwarder = new ethers.Contract(forwarderAddress, ForwarderContractABI, signer);
            const nonce = await forwarder.getNonce(signerAddress);
            console.log(forwarder)

            console.log("Nonce:", nonce.toString());

            // metamint関数のデータをエンコード
            const mintAmount = 1;
            const maxMintAmount = 10;
            const merkleProof = []; // メルクルプルーフ
            const receiver = signerAddress; // ミントを受け取るアドレス

            // metamint関数のデータエンコード
            const externalMinter = new ethers.Contract(externalMinterAddress, ExternalMinterABI, signer);
           console.log(externalMinter)
            
            const metamintData = externalMinter.interface.encodeFunctionData("metamint", [
                mintAmount,
                maxMintAmount,
                merkleProof,
                receiver
            ]);
            console.log("Encoded Data:", metamintData);

            // フォワードリクエストの作成
            const forwardRequest = {
                from: signerAddress,
                to: externalMinterAddress,
                value: 0,
                gas: 1000000,
                nonce,
                data: metamintData
            };
            console.log("Forward Request:", forwardRequest);

            // 署名の生成
            const signature = await signer.signMessage(ethers.utils.arrayify(ethers.utils.keccak256(ethers.utils.defaultAbiCoder.encode(
                ['address', 'address', 'uint256', 'uint256', 'uint256', 'bytes'],
                [forwardRequest.from, forwardRequest.to, forwardRequest.value, forwardRequest.gas, forwardRequest.nonce, forwardRequest.data]
            ))));
            console.log("Signature:", signature);

            // リレイヤーへのリクエスト送信
            const response = await axios.post('https://api.defender.openzeppelin.com/autotasks/dc788211-3ad1-489f-9ed8-4324d3f8c02a/runs/webhook/b0cf1309-d404-4478-a668-56fb34386738/LN9diJhNN9NvUCNu8McMfJ', {
                type: 'forward',
                request: forwardRequest,
                signature,
                forwarderAddress // forwarderAddressを設定
            });

            console.log('Response:', response.data);

            // フォワードリクエストの値をログに出力
            console.log(JSON.stringify([
                forwardRequest.from,
                forwardRequest.to,
                forwardRequest.value.toString(),
                forwardRequest.gas.toString(),
                forwardRequest.nonce.toString(),
                forwardRequest.data
            ]));

            setMinting(false);
            setMintSuccess(true); // 追加: ミントが成功したら、成功状態をtrueに設定

        } catch (error) {
            console.error('Error:', error);
            setMinting(false);
        }
        
    };

    const handleViewNFTClick = () => {
        navigate('/view-nft'); // 「/view-nft」へ遷移
    };

    return (
        <div>
            <button onClick={mintNFT} disabled={minting || !state.signer}>
                {minting ? 'Minting...' : 'Mint NFT'}
            </button>
            {mintSuccess && (
                <div>
                    <p>Mint Success!!</p>
                    <button onClick={handleViewNFTClick}>NFTを確認しよう！</button>
                </div>
            )}
        </div>
    );
};

export default MintNFT;
