import React, { useContext, useEffect } from 'react';
import { useAddress, useWallet, useSigner } from "@thirdweb-dev/react";
import { ConnectWallet } from "@thirdweb-dev/react";
import WalletContext from '../contexts/WalletContext';

const SET_WALLET_DATA = 'SET_WALLET_DATA';

export function WalletConnect({ children }) {
    const address = useAddress();
    const walletInstance = useWallet();
    const signer = useSigner();

    console.log("Address from useAddress hook:", address);
    console.log("Address from useSigner hook:", signer);
    console.log("Address from useWallet hook:", walletInstance);

    const { dispatch } = useContext(WalletContext);

    // address, walletInstance, signerをコンテキストに渡す
    useEffect(() => {
        dispatch({
            type: SET_WALLET_DATA,
            payload: {
                address,
                wallet: walletInstance,
                signer
            }
        });
    }, [address, walletInstance, signer, dispatch]);

    function handleConnect(walletData) {
        dispatch({ type: SET_WALLET_DATA, payload: walletData });
    }

    return (
        <>
            <ConnectWallet theme={"dark"} modalSize={"compact"} onConnect={handleConnect} />
            {children}
        </>
    );
}

export default WalletConnect;
