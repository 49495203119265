import React from 'react';

function Footer() {
  return (
    <footer>
      <p>© 2023 Minting Guide</p>
    </footer>
  );
}

export default Footer;
